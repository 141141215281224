body {
  margin: 0;
}

#sketch-wrapper {
  height: calc(100vh - 60px - 14px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  img, canvas {
    max-height: calc(100% - 20px);
    max-width: calc(100% - 20px);
    border-radius: 5px;
    overflow: hidden;
    image-rendering: crisp-edges;
  }

  .meta {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 12px * 5;
    background-color: #fff6;
    color: #555;
    padding: 10px;
    font-size: 10px;
    font-family: monospace;

    .lock {
      cursor: pointer;
      padding: 0 2px;
      line-height: 12px;
      margin-left: 5px;
      border: 0;
      font-size: 12px;
      background: none;
      opacity: .2;
      vertical-align: bottom;

      &.locked {
        opacity: 1;
        color: #f05;
      }
    }

    p {
      margin: 0 auto;
      line-height: 12px;
      width: calc(100vh - 60px - 34px);

      a {
        color: currentColor;
      }
    }
  }
}
